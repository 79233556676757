@import '../../components/GlobalStyles/colors.scss';
@import '../../components/GlobalStyles/variables.scss';
@import '../../components/GlobalStyles/buttons.scss';

.wrapper {
  z-index: 6 !important;
}

.popup {
  min-height: 150px;
  width: 290px;
  background-color: $white;
  cursor: pointer;
  padding: 20px;
  overflow: hidden;

  .title {
    color: #2c0040;
    font-family: 'Archivo Narrow';
    font-size: 21px;
    font-weight: 900;
    letter-spacing: 0.2px;
    margin-bottom: 10px;
    text-overflow: ellipsis;
  }

  a {
    color: #6e3694;
    font-family: 'Archivo Narrow' !important;

    &:hover {
      color: $darkerpurple;
      text-decoration: underline;
    }
  }

  .podDisclaimer,
  .darkPurple {
    color: #1a1a1ab3;
    font-family: 'Archivo Narrow' !important;
    line-height: 1.3rem;

    a {
      font-family: 'Archivo Narrow' !important;
      line-height: 1.3rem;
      color: $anotherPurple;
      font-size: 16px;
      text-decoration: none;

      &:hover {
        color: $darkerpurple;
        text-decoration: underline;
      }
    }
  }

  .podDisclaimer {
    padding-top: 10px;

    .podDisclaimerLink {
      margin-top: 10px;
      display: block;
    }
  }

  .grid {
    padding: 15px 0;
  }

  .contact {
    color: #2c0040;
    font-size: 16px;
    font-weight: 900;
    margin-top: 20px;
    letter-spacing: 0.2px;
  }

  .buttonPadding {
    padding: 10px 40px 40px 40px;
  }

  .contactName {
    color: $anotherPurple;
    font-family: 'Archivo Narrow';
    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 4px;

    a {
      color: $anotherPurple;
      font-family: 'Archivo Narrow';
      font-size: 16px;
      text-decoration: none;

      &:hover {
        color: $darkerpurple;
        text-decoration: underline;
      }
    }
  }

  .close {
    float: right;
    height: 20px;
    width: 20px;
    margin-right: -5px;
    font-size: 23px;
    color: $darkfont;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    height: 35px;
    // max-width: 140px;
    background: $colorfont;
    box-shadow: 0 2px 4px 0 rgba($black, 0.7);
    font-size: 16px;
    color: $white;
    font-weight: bold;
    letter-spacing: 2.81px;
    padding: 0 10px;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .button:hover {
    background: $raspberry;
  }

  .button:active {
    background: $darkraspberry;
  }

  .button:focus {
    border: 2px solid $white;
  }

  .modal {
    border-radius: 0px;

    .modalTitle {
      font-size: 1.25rem;
      font-family: 'Archivo Narrow';
      font-weight: 700;
      line-height: 1.6;
      letter-spacing: 0.2px;
      color: #2c0040;
      padding: 40px 40px 10px 40px;
    }

    .modalContent {
      font-family: 'Archivo Narrow';
      color: rgba(26, 26, 26, 0.7);
      padding: 10px 40px;
    }
  }
}
