@import '../../components/GlobalStyles/colors.scss';
@import '../../components/GlobalStyles/buttons.scss';

.container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  background-color: $backgroundColor;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  //padding: 40px;

  .body {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: visible;

    .header {
      color: $darkerpurple;
      font-family: 'Oswald';
      font-size: 19px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      text-align: left;
    }

    .content,
    .text {
      color: rgba(26, 26, 26, 0.7);
      font-family: 'Archivo Narrow';
      font-size: 16px;
      letter-spacing: 0;
      line-height: 21px;
      text-align: left;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .container {
    display: flex;
    flex-direction: row;
    width: auto;
    background-color: $backgroundColor;

    .body {
      display: flex;
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 680px) {
  .container {
    display: flex;
    flex-direction: column;
    width: auto;
    background-color: $backgroundColor;

    .body {
      display: flex;
      flex-direction: column;
      width: auto;
    }
  }
}

@media only screen and (max-width: 330px) {
  .container {
    display: flex;
    flex-direction: column;
    width: auto;
    background-color: $backgroundColor;

    .body {
      display: flex;
      flex-direction: column;
      width: auto;
    }
  }
}
